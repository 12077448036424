import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './product.css'; // Import your CSS file for styling
import productImage from '../../assets/pngicon/productmask.png';
import defaultImage from '../../assets/pngicon/defaultBG.png';
import { DataContext } from '../../App';

const ProductPage = () => {
  const data = useContext(DataContext);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getImage = (imageName) => {
    try {
      const image = require(`../../assets/pngicon/${imageName}.png`);
      return image.default || image; // For both CommonJS and ES modules compatibility
    } catch (error) {
      console.error(`Error loading image ${imageName}:`, error);
      return defaultImage;
    }
  };

  return (
    <div className='mainProdWrap'>
      <div className="product-image">
        <img src={productImage} alt="Product" />
        <div className='headingOnImage'>
        <h1 className="key-features-heading">Product Offerings</h1>
        </div>
      </div>
      <div className="corporateContainer">
        <h1 className="key-features-heading fordeaktopfeatures">Key Features</h1>
        <div className="product-cards">
          {data?.products?.length && data?.products.map((product, index) => (
            <div key={`product-${index}`} className={`product-card column-${product.Column}`}>
              <div className="product-icon">
                <img src={getImage(product.Image)} alt={product?.Heading} />
              </div>
              <ul className="features-list">
                <h3 className="sample-heading">{product?.Heading}</h3>
                {product?.Element && product?.Element.split('##').map((feature, idx) => (
                  <li key={idx}>{feature}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;

